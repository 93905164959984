<template>
  <div class="container">
    <div class="row">
      <Notif :notif="notif" />
    </div>
    <div class="col-md-4 mx-auto mt-4">
      <div class="card">
        <div class="card-header header-blue pb-2">
          <h4 class="text-center my-auto">MOT DE PASSE OUBLIE</h4>
        </div>
        <div class="card-body">
          <form>
            <template>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"
                        id="basic-addon1">
                    @
                  </span>
                </div>
                <input type="text"
                       name="email"
                       v-model="form.email"
                       class="form-control"
                       placeholder="E-mail"
                       aria-describedby="basic-addon1"
                       aria-label="E-mail" />
              </div>
              <span v-if="form.errors().has('email')"
                    v-text="form.errors().get('email')"
                    class="errorMsg"> </span>
              <div class="btn_submit">
                <input type="button"
                       value="ENVOYER"
                       class="btn btn-blue"
                       :disabled="form.empty()"
                       @click="submit" />
              </div>
              <div class="bottom_dec">
                <p>
                  <router-link :to="{ path: '/' }"
                               replace>Retour </router-link>
                </p>
              </div>
            </template>
          </form>
          <!-- <p class="errormsg">{{ forgotmsg }}</p>
          <p class="errormsg">{{ errors }}</p> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import form from "vuejs-form"
import { mapGetters, mapActions, mapMutations } from "vuex"
import Notif from "@/components/shared/Toast"

export default {
  name: "forgotPassword",
  components: {
    Notif
  },
  data: () => ({
    notif: {
      activated: "",
      type: "",
      message: ""
    },
    form: form({
      email: ""
    })
      .rules({
        email: "email|min:5|required"
      })
      .messages({
        "email.email": "Veillez entrer une adresse e-mail valid"
      })
  }),
  watch: {
    ["form.data"]: {
      deep: true,
      immediate: false,
      handler: "onFormChange"
    },
    forgotmsg(){
      if(this.forgotmsg){
        this.notif.message = this.forgotmsg
        this.notif.type = "toast-success"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.forgotMsg("")
          }.bind(this),
          3000
        )
      }
    },
    errors(){
      if(this.errors){
        this.notif.message = this.errors
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.setErors("")
          }.bind(this),
          3000
        )
      }
    }
  },
  computed: {
    ...mapGetters(["forgotmsg", "errors"])
  },
  methods: {
    ...mapActions(["forgotPassword"]),
    ...mapMutations(["forgotMsg", "setErors"]),
    onFormChange() {
      //   this.form.validate()
    },
    submit() {
      this.form.validate()
      if (
        !this.form
          .validate()
          .errors()
          .any()
      ) {
        this.forgotPassword(this.form.data)
      }
    }
  }
}
</script>
